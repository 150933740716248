import { useReducer, createContext } from 'react';


const initialState = {
	user: {
		email: '',
		first_name: '',
		last_name: '',
		country: '',
		password: ''
	}
};

const userReducer = (state, action) => {
	switch (action.type) {
		case 'SAVE_USER': return { user: action.user };
		case 'DELETE_USER': return { user: {} };
		default: state;
	}
};

export const RegisterContext = createContext({});

const RegisterStore = ({ children }) => {
	const [state, dispatch] = useReducer(userReducer, initialState);

	return <RegisterContext.Provider value={{ state, dispatch }}>{children}</RegisterContext.Provider>;
};

export const saveUserActionCreator = (user) => {
	return {
		type: 'SAVE_USER',
		user
	};
};

export const deleteUserActionCreator = () => {
	return {
		type: 'DELETE_USER',
	};
};

export default RegisterStore;