import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import Auth from './pages/Auth';
import Dashboard from './pages/Dashboard';
import Welcome from './pages/Welcome';
import Payment from './pages/Payment';
import Licenses from './pages/Dashboard/Licenses';
import Plan from './pages/Dashboard/License/Plan';
import Domain from './pages/Dashboard/License/Domain';
import Technology from './pages/Dashboard/License/Technology';
import Cancellation from './pages/Dashboard/License/Cancellation';
import Profile from './pages/Dashboard/Profile';
import { Error } from './shared/components/UIElements/Notification';

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);


const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Auth />}>
					<Route path='/register' element={<Auth />} />
					<Route path='/verify' element={<Auth />} />
					<Route path='/reset/' element={<Auth />}>
						<Route path=':hash' element={<Auth />} />
					</Route>
					<Route path='/dealify' element={<Auth />} />
					<Route path='/redeem' element={<Auth />} />
					<Route path='/appsumo' element={<Auth />} />
				</Route>

				<Route path='/welcome' element={<Welcome />} />
				<Route path='user' element={<Dashboard />} >
					<Route path='licenses' element={<Licenses />} />
					<Route path='profile' element={<Profile />} />
					<Route path='plan/:id' element={<Plan />} />
					<Route path='domain/:id' element={<Domain />} />
					<Route path='technology/:id' element={<Technology />} />
					<Route path='cancellation/:id' element={<Cancellation />} />
				</Route>
				<Route path='/onboardingpaddle' element={<Payment />} />
				<Route path='*' element={<Error status={404}></Error>} />
			</Routes>
		</BrowserRouter>
	);
};

root.render(<App />);
