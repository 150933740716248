import Button from '../../../shared/components/FormElements/Button';
import { Error } from '../../../shared/components/UIElements/Notification';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useLicense from '../../../shared/hooks/useLicense';
import { getPlanName } from '../../../shared/util';
import { useForm } from 'react-hook-form';
import Loading from '../../../shared/components/UIElements/Loading';
import { Dropdown } from '../../../shared/components/FormElements/Input';
import '../common.css';
import './index.css';
// import useSWR from 'swr';
// import dashboardApi from '../../../api/dashboard.api';

const Plan = () => {
	const { id } = useParams();
	const navigateTo = useNavigate();
	const { register, formState: { errors }, handleSubmit, control } = useForm({ mode: 'onTouched' });
	const [contactSnackbar, setContactSnackbar] = useState(false);
	const { license, updateLicenseInfo, loading, error } = useLicense(id);
	const currentPlansID = [671726, 671724, 671721];
	const hideUpdatePlanFrom = { 65052: true, 854847: true };

	if (!license) {
		return <Error status={404} />;
	}

	const planName = getPlanName(license.planId);

	const toggleContactSnackbar = () => {
		setContactSnackbar(true);
		setTimeout(() => setContactSnackbar(false), 3000);
	};

	const submitPlanChange = async (data) => {
		await updateLicenseInfo(data, 'plan');
		navigateTo('/user/licenses', { state: { success: true, changed: 'plan' } });
	};


	const options = currentPlansID.map(plan => ({ value: plan, label: getPlanName(plan) }));

	return (
		<>
			<section className='outer-container'>
				{error && <Error invert>{error.message}</Error>}
				{contactSnackbar &&
					<div style={{ border: '1px solid #1976D2', padding: '1em', color: '#1976D2' }}>To update your billing information, please contact support@atomchat.com</div>
				}
				<h1 className="heading">Plan</h1>
				<div className='container'>
					<div>
						<div className="current">
							<h2>Current Plan</h2>
							<div className="value">{planName}</div>
						</div>
						<div className="options">
							<Button size='small' inverse onClick={toggleContactSnackbar}>Update billing details</Button>
							<Button to={`/user/cancellation/${id}`} size='small' inverse>Cancel Subscription</Button>

						</div>
						<form className="change" onSubmit={handleSubmit(submitPlanChange)}>
							<h2>Change Plan</h2>
							<Dropdown
								options={options}
								id="planId"
								label="Plans"
								register={register}
								validations={{ required: true }}
								disabled={hideUpdatePlanFrom[license?.planId]}
								errors={errors.planId}
								errorText="Please select a plan"
								control={control}
							/>
							<Button disabled={loading} size='full' type='submit'>{loading ? <Loading /> : 'Change'}</Button>
						</form>
					</div>
				</div>
			</section>
		</>
	);
};

export default Plan;