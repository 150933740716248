import React, { useState } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Carousel.css';
import { VelvetLogo, PhilTalkLogo } from '../UIElements/LogoSVGs';

export const dummy_content = [
	{
		id: 1,
		img: <PhilTalkLogo />,
		text: '"Among many other unique needs, we needed a chat integration that offered real time messaging, audio calls, video calls and a white board option similar to competitors on the market. Searching the market for the right solution was difficult and a bit scary. Rarely you will find a product that is both compelling and supported by an incredible team, while there may be products like AtomChat on the market, from our personal experience, NONE of these solutions comes close in terms of customer service and quality. Everything we need, from messaging to collaborative options is offered by AtomChat. We have not looked back since choosing AtomChat and are looking forward to a long and fruitful future with them!".',
		author: 'Kameron James Moshier',
		reference: 'Co-Founder & Co-CEO of philtalk, USA',
	},
	{
		id: 2,
		img: <VelvetLogo />,
		text: '"I have used AtomChat for nearly 5 months, and through the integration process with my app I have not only been helped quickly by support by a number of times, but it was also quite seamless. They have integration guides for a variety of software. The docked and embedded views for the chat are fast, easy to setup, and still very modular. We are using AtomChat to create a communication network for a marketplace of funds and investors, and using their instant messaging and video/audio calls."',
		author: 'Alex Johnson',
		reference: 'CTO at Velvet, USA',
	},
];

export const CarouselItem = ({ children, width }) => {
	return (
		<>
			<div className="carousel-items" style={{ width: width }}>
				{children}
			</div>
		</>
	);
};

const Carousel = ({ children }) => {
	const [, setActiveIndex] = useState(0);

	// eslint-disable-next-line no-unused-vars
	const updateIndex = (newIndex) => {
		if (newIndex < 0) {
			newIndex = 0;
		} else if (newIndex >= React.Children.count(children)) {
			newIndex = React.Children.count(children) - 1;
		}

		setActiveIndex(newIndex);
	};

	const sliderSettings = {
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: false,
		initialSlide: 1,
		arrows: true,
	};


	return (
		<div className='content'>
			<Slider {...sliderSettings}>
				{dummy_content.map((content) => {


					return (
						<div className='carousel-content' key={content.id}>
							<div >
								{content.img}
							</div>
							<p className="carousel-text">{content.text}</p>
							<h2 className="carousel-header">{content.author}</h2>
							<h5 className="carousel-subheader">{content.reference}</h5>
						</div>
					);
				})}
			</Slider>
		</div>
	);
};

export default Carousel;
