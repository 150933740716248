export const VALID_MIN_LENGTH = (value = 1) => ({ type: 'minLength', value });
export const VALID_EMAIL = () => ({ type: 'email' });
export const VALID_PASSWORD = () => ({ type: 'password' });
export const VALID_DEALIFY_CODE = () => ({ type: 'dealify_code' });

const validator = (validation, required = false) => {
	let result = null;

	if (validation.type === 'email') {
		result = {
			pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		};
	} else if (validation.type === 'minLength' && validation.value) {
		result = {
			minLength: validation.value
		};
	} else if (validation.type === 'password') {
		result = {
			minLength: 8,
			pattern: /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{8,})\S$/
		};
	} else if (validation.type === 'dealify_code') {
		result = {
			length: 12,
			pattern: /^(ACDL|atom)-[A-Z0-9]{7}$/
		};
	}

	else throw Error('Invalid validation type');

	return { ...result, required: required };
};

export default validator;