import './Modal.css';

const Modal = props => {
	return (
		<div className='modal'>
			<div className={`container ${props.size}`}>
				<div className="content">
					{props.content}
				</div>
				{props.footer}
			</div>
		</div>
	);
};

export default Modal;
