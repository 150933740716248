import { useState } from 'react';
import { useForm } from 'react-hook-form';
import validator, { VALID_EMAIL, VALID_PASSWORD } from '../../../shared/util/validators';
import Loading from '../../../shared/components/UIElements/Loading';
import Button from '../../../shared/components/FormElements/Button';
import { Error } from '../../../shared/components/UIElements/Notification';
import { Input } from '../../../shared/components/FormElements/Input';
import { useNavigate } from 'react-router-dom';

export const ResetPasswordForm = ({ resetPassword }) => {
	const { handleSubmit, register, formState: { errors }, watch } = useForm({ mode: 'onTouched' });
	const [serverError, setServerError] = useState('');
	const [loading, setLoading] = useState(false);
	const navigateTo = useNavigate();

	const handlePasswordReset = async data => {
		setLoading(true);
		setServerError(false);

		try {
			await resetPassword(data.password);
			navigateTo('/');
		} catch (error) {
			setServerError(error.message);
		}

		setLoading(false);
	};


	const passwordsMatch = (password) => {
		return password === watch('password');
	};

	return (
		<div className="form-container">
			{serverError && <Error invert>{serverError}</Error>}
			<h2 className="form-header">Reset Password</h2>
			<form onSubmit={handleSubmit(handlePasswordReset)}>
				<Input
					element="input"
					id="password"
					type="password"
					label="Password"
					register={register}
					validations={validator(VALID_PASSWORD(), true)}
					errors={errors.password}
					errorText='Please enter a valid password with at least 8 characters including uppercase and numeric characters' />

				<Input
					element="input"
					id="confirmPass"
					type="password"
					label="Confirm Password"
					register={register}
					validations={{ validate: value => passwordsMatch(value) }}
					errors={errors.confirmPass}
					errorText='Password does not match' />
				<Button type='submit'>{loading ? <Loading /> : 'Reset'}</Button>
			</form>
		</div>
	);
};

export const ForgotPasswordForm = ({ forgotPasswordFor }) => {
	const [loading, setLoading] = useState(false);
	const [serverError, setServerError] = useState('');
	const navigateTo = useNavigate();
	const { register, formState: { errors }, handleSubmit } = useForm({ mode: 'onTouched' });

	const handlePasswordReset = async data => {
		setLoading(true);
		setServerError(false);
		try {
			await forgotPasswordFor(data.reset);
			navigateTo('/', { state: { reset: true } });
		} catch (error) {
			setServerError(error.message);
		}
		setLoading(false);
	};

	return (
		<div className="form-container">
			{serverError && <Error invert>{serverError}</Error>}
			<h2 className="form-header">Reset Password</h2>
			<form onSubmit={handleSubmit(handlePasswordReset)}>
				<Input
					element="input"
					id="reset"
					type="email"
					label="Email"
					register={register}
					validations={validator(VALID_EMAIL(), true)}
					errors={errors.reset}
					errorText='Please enter a valid email address' />
				<Button type='submit'>{loading ? <Loading /> : 'Reset'}</Button>
			</form>
		</div>
	);
};