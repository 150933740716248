import { createAxiosClient, getAuthHeader, handleApiError } from './common';


const setOnboardingInfo = async (info) => {
	const client = createAxiosClient(getAuthHeader());

	try {
		return client.put('/api/user/onboard', info);
	} catch (error) {
		throw handleApiError(error);
	}
};

export default { setOnboardingInfo };