import axios from 'axios';

export const createAxiosClient = (headers = null) => axios.create({ headers });


export const getAuthHeader = () => {
	try {
		const token = JSON.parse(localStorage.getItem('auth:user'));
		return ({ Authorization: `bearer ${token.jwt}` });
	} catch (error) {
		// Do nothing
	}
};

export const handleApiError = (error) => {
	if (!error.response.data) {
		throw {
			message: 'Connection to server failed. Please contact support@atomchat.com.'
		};
	} else {
		throw {
			...error.response.data.error
		};
	}
};

export const handleFetchApiError = (error) => {
	if (!error.status) {
		throw {
			message: 'Connection to server failed. Please contact support@atomchat.com.'
		};
	} else if (error.info.error) {
		if (error.info.error.name === 'CANNOT_CHANGE_PLANS') {
			throw {
				status: error.status,
				message: error.info.error.message
			};
		}

		throw {
			status: error.status,
			message: error.info.error.message + '. Contact support@atomchat.com.'
		};
	} else {
		throw {
			status: error.status,
			message: error.info.message
		};
	}
};