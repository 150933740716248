/* eslint-disable no-unused-vars */

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Auth from '../Auth';
import { Input } from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import Loading from '../../shared/components/UIElements/Loading';
import './index.css';
import authApi from '../../api/auth.api';
import { Error } from '../../shared/components/UIElements/Notification';
import validator, { VALID_DEALIFY_CODE } from '../../shared/util/validators';

const Redeem = () => {
	const { register, formState: { errors }, handleSubmit } = useForm({ mode: 'onTouched' });
	const [serverError, setServerError] = useState('');
	const navigateTo = useNavigate();
	const [loading, setLoading] = useState(false);
	const userFound = JSON.parse(localStorage.getItem('auth:user'));

	const handleVerification = async ({ code }) => {
		setLoading(true);
		try {
			setServerError('');
			const value = code.split('-')[1];
			if (value === 'L1F71M3') {
				const { data } = await authApi.activateLifetimeDeal();
				localStorage.setItem('auth:user', JSON.stringify({ ...userFound, ...data }));
				localStorage.setItem('pref:user', JSON.stringify({ showSetInfoModal: true }));
			}
			else {
				const { data } = await authApi.verifyDealifyCode({ code: value });
				localStorage.setItem('auth:user', JSON.stringify({ ...userFound, ...data }));
			}

			navigateTo('/welcome');
		} catch (error) {
			setServerError(error.message);
		}
		setLoading(false);
	};

	return (
		<div className="form-container">
			{serverError && <Error invert>{serverError}</Error>}
			<h2 className='form-header'>Redeem your code!</h2>
			<form onSubmit={handleSubmit(handleVerification)}>
				<Input
					element="input"
					id="code"
					type="text"
					placeholder='ACDL-XXXXXX'
					register={register}
					validations={validator(VALID_DEALIFY_CODE(), true)}
					errors={errors.code}
					errorText='Please enter a valid code' />
				<Button type='submit' disabled={loading}>{loading ? <Loading /> : 'Proceed'}</Button>
			</form>
		</div>
	);
};

export default Redeem;