import { useEffect, useState } from 'react';
import api from '../../api/dashboard.api';
import onboardingApi from '../../api/onboarding.api';
import Button from '../../shared/components/FormElements/Button';
import { WelcomeWave, Arrow, DeveloperImage, WebsiteOwnerImage, OrganisationOwnerImage, Check } from '../../shared/components/UIElements/ImageSvgs';
import { AtomChatLogo } from '../../shared/components/UIElements/LogoSVGs';
import Loading from '../../shared/components/UIElements/Loading';
import './index.css';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import Select from 'react-select';
import { Error } from '../../shared/components/UIElements/Notification';

const Welcome = () => {
	const [step, setStep] = useState({ number: 1 });
	const navigateTo = useNavigate();
	const { state } = useLocation();
	const [onboardingType, setOnBoardingType] = useState('');

	useEffect(() => {
		const userFound = localStorage.getItem('auth:user');
		if (!userFound) {
			navigateTo('/');
			return;
		}

		const user = JSON.parse(userFound);

		if (!user.onboardingRequired && !(state && state.onboardingRequired)) {
			return navigateTo('/', { replace: true });
		}

		setOnBoardingType(user.onboardingType);
	}, []);

	return (
		<div className='panel-container'>
			<LeftPanel step={step.number} onboardingType={onboardingType} />
			<RightPanel step={step.number} setStep={setStep} onboardingType={onboardingType} />
		</div >
	);
};


const LeftPanel = ({ step, onboardingType }) => {
	const content = ['Welcome!', 'We want to know you better', 'What does your website do?'];
	onboardingType !== 'appsumo' && content.push('Domain and technology');
	const isCurrentStep = (index) => step === index + 1;
	const isComplete = (index) => step > index + 1;

	return (
		<div className="left-panel">
			<div style={{ padding: '2em' }}>
				<AtomChatLogo height='2.5em' />
				<ul id='about-steps'>
					{
						content.map((text, index) => (
							<li key={index}>
								<span className={`step-number ${isCurrentStep(index) && 'active'} ${isComplete(index) && 'complete'}`}>{isComplete(index) ? <Check height='12' /> : index + 1}</span>
								<span className={`step-text ${isCurrentStep(index) && 'active'} ${isComplete(index) && 'complete'}`} > {text}</span>
								{step === (index + 1) && <Arrow className='current-step-arrow' />}
							</li>)
						)
					}
				</ul>
			</div>
		</div >
	);
};

const RightPanel = ({ step, setStep, onboardingType }) => {
	const [onboardingInfo, setOnboardingInfo] = useState({});
	const navigateTo = useNavigate();
	const [errorMessage, setErrorMessage] = useState('');

	/**
	 * 
	 * @param {object} data Data of the current step
	 */

	const moveToNextStep = async (data) => {
		setOnboardingInfo({ ...onboardingInfo, ...data });

		// The Final Steps
		if (onboardingType === 'appsumo' && step === 3) {
			try {
				await onboardingApi.setOnboardingInfo({ ...onboardingInfo, ...data });
				const userInLS = JSON.parse(localStorage.getItem('auth:user'));

				delete userInLS.onboardingRequired;
				delete userInLS.onboardingType;

				localStorage.setItem('auth:user', JSON.stringify(userInLS));
				localStorage.setItem('pref:user', JSON.stringify({ showSetInfoModal: true }));

				navigateTo('/user/licenses');
			} catch (error) {
				return setErrorMessage(error.message);
			}
		}

		if (step > 3) {
			try {

				// onboardingInfo doesn't reflect the change until after the function finishes
				// Therefore, to send domain and web_integration_ids, we need to add that data to the request.
				const response = await onboardingApi.setOnboardingInfo({ ...onboardingInfo, ...data });

				if (response.data.paymentRequired) {
					// Regular flow
					localStorage.setItem('paddle:passthrough', JSON.stringify(data));
					const product = window.localStorage.getItem('paddle:id');
					navigateTo(`/onboardingpaddle?product=${product}`, { replace: true, state: { fromOnboarding: true } });
				} else {
					// Lifetime Deals Flow
					const userInLS = JSON.parse(localStorage.getItem('auth:user'));
					localStorage.setItem('auth:user', JSON.stringify({ ...userInLS, onboardingRequired: false }));
					localStorage.removeItem('pref:user');
					navigateTo('/');
				}
			} catch (error) {
				return setErrorMessage(error.message);
			}
		}

		setStep({ number: step + 1 });
	};

	const moveToPreviousStep = (error = false) => {
		if (error) {
			setErrorMessage('We are having trouble connecting to the server. Try again later.');
		}
		setStep({ number: step - 1 });
	};

	const DisplayScreen = () => {
		switch (step) {
			case 1: return <WelcomeScreen moveToNextStep={moveToNextStep} />;
			case 2: return <UseScreen moveToNextStep={moveToNextStep} moveToPreviousStep={moveToPreviousStep} />;
			case 3: return <WebsiteTypeScreen moveToNextStep={moveToNextStep} moveToPreviousStep={moveToPreviousStep} />;
			case 4: return <DomainAndTechnologyScreen moveToPreviousStep={moveToPreviousStep} moveToNextStep={moveToNextStep} />;
		}
	};

	return (
		<div className="welcome right-panel" style={{ padding: '1em 5em', width: '100%' }}>
			{errorMessage && <Error invert>{errorMessage}</Error>}
			<DisplayScreen />
		</div>
	);
};

const WelcomeScreen = ({ moveToNextStep }) => {
	const [user, setUser] = useState({});
	const navigateTo = useNavigate();

	useEffect(() => {
		const userFound = localStorage.getItem('auth:user');
		if (!userFound) {
			navigateTo('/register', { replace: true });
			return;
		}

		setUser(JSON.parse(userFound));
	}, []);

	return (
		<>
			<WelcomeWave height='20em' />
			<h1 className='welcome-title'>Welcome, {user.firstName}!</h1>
			<p className='welcome-text'>Let&apos;s set up your profile to get the most of AtomChat for your website.</p>
			<Button onClick={() => moveToNextStep({})}>Let&apos;s Begin</Button>
		</>
	);
};

const UseScreen = ({ moveToNextStep, moveToPreviousStep }) => {
	const [selected, setSelected] = useState(-1);
	const [otherText, setOtherText] = useState('');

	const handleChangeText = ({ target }) => {
		setOtherText(target.value);
		setSelected(-1);
	};

	const onSelect = (number) => () => {
		if (selected === number) {
			return setSelected(-1);
		}

		setSelected(number);
		setOtherText('');
	};

	const content = [
		{
			image: DeveloperImage,
			text: 'A developer testing for a client'
		},
		{
			image: WebsiteOwnerImage,
			text: 'A website owner',
		},
		{
			image: OrganisationOwnerImage,
			text: 'A part of an organisation'
		}
	];

	return (
		<div className='screen-container'>
			<div className='screen-content'>
				<section>
					<h1 className='margin-tb'>You will use AtomChat as</h1>
					<div className='grid-cols'>
						{
							content.map((item, index) => {
								return (
									<ScreenItem
										className={`screen-item ${(selected === index) && 'selected'}`}
										key={index}
										Image={item.image}
										text={item.text}
										onClick={onSelect(index)}
									/>
								);
							})
						}
					</div>
				</section>
				<section className='margin-tb'>
					<h2 style={{ marginBottom: '.2em' }}>Other</h2>
					<label htmlFor='other-option'>Tell us how you&apos;ll use AtomChat!</label>
					<input onChange={handleChangeText} value={otherText} type='text' id='other-option' className='other-option' />
				</section>
			</div>
			<div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '1em' }}>
				<Button inverse onClick={moveToPreviousStep}>Previous</Button>
				<Button disabled={selected === -1 && !otherText.length} onClick={() => moveToNextStep({ who: content[selected]?.text || otherText })}>Next</Button>
			</div>
		</div>
	);
};

const WebsiteTypeScreen = ({ moveToNextStep, moveToPreviousStep }) => {
	const [selected, setSelected] = useState(-1);
	const [otherText, setOtherText] = useState('');
	const [category, setCategory] = useState('');

	const content = [
		{
			name: 'Marketplace',
			submenu: ['Freelance Marketplace', 'Multivendor Marketplace', 'Consultancy Marketplace', 'Expert-Knowledge Seeker Marketplace', 'Coaching Marketplace']
		},
		{
			name: 'Community',
			submenu: ['Social Network', 'Closed Community', 'Online Dating', 'Forum']
		},
		{
			name: 'Social Network',
			submenu: ['We organize events for companies', 'Events take place on our websites']
		},
		{
			name: 'E-Learning or Coaching Platform',
			submenu: ['We are an educational institution', 'We are an e-learning software', 'Online Coaching']
		}];


	const itemsToDisplayForCategory = content.find(cat => cat.name === category);

	const handleChangeText = ({ target }) => {
		setOtherText(target.value);
		setSelected(-1);
	};

	const onSelect = (number) => () => {
		if (selected === number) {
			return setSelected(-1);
		}

		setSelected(number);
		setOtherText('');
	};

	const ListWebsiteCategory = () => (
		<section>
			<h1>Your website is a</h1>
			{
				content.map((item, index) => {
					return (
						<WebsiteTypeListItem key={index} text={item.name} className='website-type-list' onClick={() => setCategory(item.name)} />
					);
				})
			}
		</section>
	);

	const ListWebsiteType = ({ category }) => {
		if (!category && otherText) {
			return;
		}

		return (
			<section>
				<div className='back-nav' onClick={() => setCategory('')}>
					<Arrow height='2em' />
					<div className="text">Back to {category}</div>
				</div>
				<div className='grid-cols'>
					{
						itemsToDisplayForCategory.submenu.map((item, index) => {
							return (
								<WebsiteTypeItem text={item} key={index} className={`screen-item center-text ${selected === index && 'selected'}`} onClick={onSelect(index)} />
							);
						})
					}
				</div>
			</section>
		);
	};

	return (
		<div className='screen-container'>
			<div className='screen-content'>
				{!category ? <ListWebsiteCategory /> : <ListWebsiteType category={category} />}
				<section className='margin-tb'>
					<h2>None of the above?</h2>
					<label htmlFor='other-option'>Please tell us what your website does</label>
					<input onChange={handleChangeText} value={otherText} type='text' id='other-option' className='other-option' />
				</section>
			</div>
			<div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '1em' }}>
				<Button inverse onClick={moveToPreviousStep}>Previous</Button>
				<Button disabled={selected === -1 && !otherText.length} onClick={() => moveToNextStep({ websiteType: category || otherText, websiteInfo: itemsToDisplayForCategory?.submenu[selected] })}>Next</Button>
			</div>
		</div>
	);
};

const DomainAndTechnologyScreen = ({ moveToPreviousStep, moveToNextStep }) => {
	const { data, error } = useSWR('/integrations', api.fetcher);
	const [tech, setSelectedTech] = useState(0);
	const [domain, setDomain] = useState('');
	const [otherText, setOtherText] = useState('');

	if (!data && !error) {
		return <Loading />;
	}

	if (error) {
		moveToPreviousStep(true);
	}

	const handleChangeOtherText = ({ target }) => {
		setOtherText(target.value);
	};

	const handleChangeDomainText = ({ target }) => {
		setDomain(target.value);
	};

	const isValidURL = (value) => {
		let timesMustMatch = '*';

		if (/www\./.test(value)) {
			timesMustMatch = '+';
		}

		const pattern = new RegExp(`[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)${timesMustMatch}(\\.[a-z]{2,})(:[0-9]{1,5})?(\\/[a-z0-9\\-._~:\\/?#[\\]@!$&'()*+,;=]*)?`, 'gm');

		return pattern.test(value) || value === 'localhost';
	};

	const categories = ['software', 'language', 'none'];
	const options = categories.map(category => ({
		label: category.toUpperCase(),
		options: data.filter(tech => tech.type === category).map(tech => ({ value: tech.id, label: tech.name })),
	}));


	return (
		<div className='screen-container' style={{ width: '100%' }}>
			<div className='screen-content'>
				<section>
					<h1>Enter your domain</h1>
					<label htmlFor='domain'>Your website URL</label>
					<input onChange={handleChangeDomainText} value={domain} type='text' id='domain' className='other-option' />
					<p className='domain-instruction'>
						This domain should be secure! Don&apos;t have an SSL certificate yet?
						<a href='https://www.gogetssl.com/' target='_blank' rel='noreferrer'> Try  GoGetSSL, </a>
						<a href='https://zerossl.com/' target='_blank' rel='noreferrer'>SSL For Free ZeroSSL.</a></p>
				</section>
				<section className='margin-tb'>
					<h1>Technology</h1>
					<Select options={options} onChange={(item) => setSelectedTech(item.value)} />
				</section>
				{tech === 107 &&
					<section className='margin-tb'>
						<h2>None of the above?</h2>
						<label htmlFor='other-option'>Please tell us what technology your website uses</label>
						<input onChange={handleChangeOtherText} value={otherText} type='text' id='other-option' className='other-option' />
					</section>
				}
			</div>
			<div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '1em' }}>
				<Button inverse onClick={moveToPreviousStep}>Previous</Button>
				<Button disabled={!tech || !isValidURL(domain) || (tech === 107 && !otherText.length)} onClick={() => moveToNextStep({ domain, web_integration_ids: tech, web_integration_ids_custom: otherText })}>Get Started!</Button>
			</div>
		</div>
	);
};

const ScreenItem = ({ Image, text, className, onClick }) => {
	return (
		<div className={className} onClick={onClick}>
			<Image className='item-image' />
			<div className='item-text'>{text}</div>
		</div>
	);
};

const WebsiteTypeListItem = ({ text, className, onClick }) => {
	return (
		<div className={className} onClick={onClick}>
			{text}
		</div>
	);
};

const WebsiteTypeItem = ({ text, className, onClick }) => {
	return (
		<div className={className} onClick={onClick}>
			<div className='item-text'>{text}</div>
		</div>
	);
};


// const WebsiteTypeItemLogo = ({ Logo, className, onClick }) => {
// 	return (
// 		<div className={className} onClick={onClick}>
// 			<Logo height='4em' />
// 		</div>
// 	);
// };

export default Welcome;