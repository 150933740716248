import './Loading.css';

const Loading = ({ size }) => {
	let customStyle = {};
	if (size === 'large') {
		customStyle = { width: '4em', height: '4em' };
	}

	return <div style={{ width: '1.5em', height: '1.5em', margin: '0 auto', ...customStyle }} aria-label='loading icon' className='loading-icon'></div>;
};

export default Loading;