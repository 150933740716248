import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSWR, { preload } from 'swr';
import { useForm } from 'react-hook-form';

import { CollapseIcon, EditText, ExpandIcon } from '../../shared/components/UIElements/LogoSVGs';
import Button from '../../shared/components/FormElements/Button';
import api from '../../api/dashboard.api';
import './index.css';
import './common.css';
import Loading from '../../shared/components/UIElements/Loading';
import { getPlanName } from '../../shared/util';
import { Error, Success } from '../../shared/components/UIElements/Notification';
import { useEffect } from 'react';
import dashboardApi from '../../api/dashboard.api';
import { RadioButton } from '../../shared/components/FormElements/Input';
import Modal from '../../shared/components/UIElements/Modal';


const Licenses = () => {
	const { state } = useLocation();
	const [showSuccess, setShowSuccess] = useState(state && state.success);
	const [openIntegrateModal, setOpenIntegrateModal] = useState(state && state.showIntegrationModal);
	const [openSetInfoModal, setOpenSetInfoModal] = useState(false);

	const redirectedFromPayment = state && state.redirectedFromPayment;

	let { data, mutate, error } = useSWR('/licenses', api.fetcher, {
		onErrorRetry: (error, key, _, revalidate, { retryCount }) => {
			// Never retry on 404.
			if (error.status === 404) return;

			// Only retry up to 10 times.
			if (retryCount >= 10) return;

			// Retry after 5 seconds.
			setTimeout(() => revalidate({ retryCount }), 5000);
		},
	});


	useEffect(() => {
		if (data) {
			const getPref = JSON.parse(localStorage.getItem('pref:user'));
			setOpenSetInfoModal(getPref?.showSetInfoModal);
		}
	}, [data]);

	if (!data && !error) {
		return (
			<div style={{ height: '80vh' }} className='center'>
				<Loading size='large' />
			</div>
		);
	}

	if (showSuccess) {
		setTimeout(() => {
			setShowSuccess(false);
		}, 4000);
	}


	return (
		<>
			{openIntegrateModal && <IntegrateModal setIsOpen={setOpenIntegrateModal} />}
			{openSetInfoModal && <SetInfoModal setIsOpen={setOpenSetInfoModal} />}
			{<RemoveLicensesModal licenses={data.licenses} open={data.promptDelete} countToDelete={data.countToDelete} mutate={mutate} />}
			<div className="container">
				{showSuccess && <Success>Successfully changed the {state.changed} for the license!</Success>}
				{error && !data && <Error invert>{error.message}</Error>}
				<section className='licenses'>
					<h1 className='heading'>Your Licenses</h1>
					{data.licenses.length ?
						<LicenseList licenses={data.licenses} />
						:
						<EmptyLicenseBox redirectedFromPayment={redirectedFromPayment}/>
					}
				</section>
				<section className='faq'>
					<h1 className='heading'>FAQs</h1>
					<FAQ />
				</section>
			</div>
		</>
	);
};

const IntegrateModal = ({ setIsOpen }) => {
	const [selected, setSelected] = useState('documentation');

	const handleDocumentation = () => {
		setIsOpen(false);
		window.open('https://help.atomchat.com/support/solutions/folders/82000695289', '__blank');
	};

	const handleSession = () => {
		setIsOpen(false);
		window.open('https://calendly.com/atomchat-support', '__blank');
	};

	const Content = () => (
		<>
			<h1 className='heading'>Integrate AtomChat</h1>
			<p>Integrating AtomChat involves adding a few lines of Javascript code to your website.</p>
			<div>
				<RadioButton name='integrate-radio' label='I can do it myself' value='documentation' checked={selected} onChange={() => setSelected('documentation')} />
				<RadioButton name='integrate-radio' label='I don’t know code and need help' value='session' checked={selected} onChange={() => setSelected('session')} />
			</div>
		</>
	);

	const Footer = () => (
		selected === 'documentation' ?
			<Button onClick={handleDocumentation}>Go to documentation</Button> :
			<Button onClick={handleSession}>Schedule a session</Button>
	);

	return <Modal content={<Content />} footer={<Footer />} />;
};

const SetInfoModal = ({ setIsOpen }) => {
	const [doNotRemind, setDoNotRemind] = useState(false);

	const handleClickOk = () => {
		if (doNotRemind) localStorage.removeItem('pref:user');
		setIsOpen(false);
	};

	const Content = () => (
		<>
			<h1 className='heading'>Hello There!</h1>
			<p>You have licenses that are not active yet. When you want to use a license:</p>
			<ul style={{ marginLeft: '1em' }}>
				<li>Set the Domain of the license by clicking on the <EditText height='1em' /> icon at the top of the license.</li>
				<li>Set the Technology of the license by clicking on the <EditText height='1em' /> icon near the bottom of the license.</li>
				<li>Click on the Admin Panel Button.</li>
			</ul>
		</>
	);

	const Footer = () => (
		<>
			<div>
				<input type="checkbox" onChange={() => setDoNotRemind(!doNotRemind)} checked={doNotRemind} style={{ marginRight: '.5em' }}></input>
				<label htmlFor="myCheckbox">Do not remind me again</label>
			</div>
			<Button onClick={handleClickOk}>Ok</Button>
		</>
	);


	return <Modal size='small' content={<Content />} footer={<Footer />} />;
};

const RemoveLicensesModal = ({ licenses, open, countToDelete, mutate }) => {
	const [openModal, setIsOpen] = useState(false);
	const { register, getValues, setValue } = useForm();
	const [leftToRemove, setLeftToRemove] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setIsOpen(open);
		setLeftToRemove(countToDelete);
	}, [open, countToDelete]);

	if (!openModal) {
		return null;
	}

	const handleSelectUnused = () => {
		if (!leftToRemove) return;

		const alreadySelected = getValues().ids || [];

		// Finding unused licenses that are not already selected
		const unusedLicenses =
			licenses
				.filter(license => !(license.domain && license.tech))
				.map(license => license.appId.toString())
				.filter(id => !alreadySelected.some(elem => elem === id))
				.slice(0, leftToRemove);

		const selectCheckboxes = unusedLicenses.concat(alreadySelected);

		setValue('ids', selectCheckboxes);
		setLeftToRemove(leftToRemove - unusedLicenses.length);
	};

	const handleCheckboxChange = (checked) => {
		const removed = checked ? -1 : 1;
		setLeftToRemove(leftToRemove + removed);
	};

	const handleClickRemove = async () => {
		const ids = getValues();
		setLoading(true);
		try {
			const data = await dashboardApi.deletePlans(ids);
			mutate({ licenses: data });
			setIsOpen(false);
		} catch (error) {
			//
		}

		setLoading(false);
	};

	const Content = () => (
		<>
			<h1 className="heading">Remove Licenses</h1>
			<p>You have downgraded your AppSumo plan. Please select any {countToDelete} licenses to remove.</p>
			<div className='space-between'>
				<p><span className='text-bold'>{leftToRemove}</span> licenses left</p>
				<Button size='small' inverse onClick={handleSelectUnused} style={{ alignSelf: 'unset' }}>Automatically select disabled licenses</Button>
			</div>
			<table>
				<thead>
					<tr>
						<th className='cell text-left'>ID</th>
						<th className='cell text-left'>Enabled</th>
					</tr>
				</thead>
				<tbody>
					{licenses.map((license) => {
						return (
							<tr key={license.appId} style={{ borderTop: '1px solid #ff666633' }}>
								<td className='cell'>{license.appId}</td>
								<td className='cell'>
									{license.tech && license.domain ? 'Yes' : 'No'}
								</td>
								<td className='cell text-right'>
									<input
										type="checkbox"
										name={license.appId}
										value={license.appId}
										onClick={({ target }) => handleCheckboxChange(target.checked)}
										{...register('ids')}
									/>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);

	const Footer = () => (
		<Button onClick={handleClickRemove} disabled={leftToRemove || loading}>{loading ? <Loading /> : 'Remove'}</Button>
	);

	return <Modal content={<Content />} footer={<Footer />} />;
};

const LicenseList = ({ licenses }) => {
	return (
		<>
			<div className='licenses-grid'>
				{
					licenses.map(license => <License key={license.appId} license={license} />)
				}
			</div>
		</>
	);
};

const EmptyLicenseBox = (props) => {
	let heading = 'Subscribe to AtomChat!';
	let text = 'Looks like you are not currently subscribed to any of our plans. Choose the best plan for you and subscribe to get your platform chat ready in minutes!';
	let cta = <Button size='small' href='https://www.atomchat.com/pricing'>Start Trial</Button>;

	if (props.redirectedFromPayment) {
		heading = 'Welcome to AtomChat';
		text = 'We have successfully created your AtomChat plan! We are working on your license right now and once it is created, it will appear here.';
		cta = <Loading size={'large'}/>;
	}

	return (
		<div className="center">
			<div className='info-box'>
				<h1>{heading}</h1>
				<p>{text}</p>
				{cta}
			</div>
		</div >
	);
};

const License = ({ license }) => {
	const navigateTo = useNavigate();
	const { appId, domain, license_key, planId, tech, isActive } = license;
	const [loadingAdminLink, setLoadingAdminLink] = useState(false);

	const [showTooltip, setShowTooltip] = useState(false);
	const [tooltipPosition, setTooltipPosition] = useState({});
	const disabledLicense = !tech || !domain;

	let showManage = true;

	if (planId === '854847' || planId === '65052') {
		const startedOn = license.start_date * 1000;
		const today = Date.now();

		var delta = Math.abs(today - startedOn) / 1000;

		var days = Math.floor(delta / 86400);

		showManage = days < 30;
	}

	useEffect(() => {
		if (planId === 'dealify_plan' && domain === null && tech === null) {
			return navigateTo('/welcome', { state: { onboardingRequired: true } });
		}
	}, []);

	const getAdminLink = async () => {
		setLoadingAdminLink(true);
		const data = await dashboardApi.fetchAdminLink(appId);
		window.open(data.link, '__blank');
		setLoadingAdminLink(false);
	};

	const handleMouseOver = (event) => {
		if (showTooltip || !disabledLicense) {
			setShowTooltip(false);
		} else {
			setShowTooltip(true);
			setTooltipPosition({
				left: event.clientX,
				top: event.clientY + 10,
			});
		}
	};


	// Pre-fetching Users and Groups of the app in case user navigates to the cancel page where we'll need this info.
	const fetchAppData = () => preload('/licenses/data/' + appId, dashboardApi.fetcher);
	const getUpgradeButton = () => {
		if (planId.match(/atomchat-2023_tier[1-4]/)?.length) {
			return <Button href={`https://appsumo.com/account/redemption/${license.ref}/#change-plan`} size='small' inverse>Edit Plan</Button>;
		}

		return <Button to={`/user/plan/${appId}`} onHover={fetchAppData} size='small' inverse>Edit Plan</Button>;
	};

	return (
		<div className='license'>
			<div className="header">
				<div className="logo"></div>
				<div className="domain">{domain || '(Set Domain here)'}</div>
				<Link to={`/user/domain/${appId}`}><EditText height='1.25em' /></Link>
			</div>
			<div className="info">
				<div className="item">App ID: <span className='value'>{appId}</span></div>
				<div className="item">License Key: <div className='value'>{license_key}</div></div>
				<div className="item">Plan: <span className='value'>{getPlanName(planId)}</span></div>
				<div className="item">Technology: <span className='value'>{tech || '(Set Technology here)'}<Link to={`/user/technology/${appId}`}><EditText height='1.25em' /></Link></span></div>
			</div>
			<div className='buttons'>
				{isActive ? (
					<>
						<Button onClick={getAdminLink} disabled={loadingAdminLink || disabledLicense} size='small' showTooltip={showTooltip} tooltipPosition={tooltipPosition} tooltipText='Set the domain and technology to enable the license' handleMouseOver={handleMouseOver} handleMouseOut={handleMouseOver}>{loadingAdminLink ? <Loading /> : 'Admin Panel'}</Button>
						{planId !== 'dealify_plan' && showManage && getUpgradeButton()}
					</>
				) : (
					<Button size='full' href='https://www.atomchat.com/pricing'>Resubscribe</Button>
				)}
			</div>
		</div>
	);
};

const FAQ = () => {
	const data = [
		{
			query: 'Can I change my plan?',
			response: 'After your trial period is completed, yes. Please click on the Edit Plan button and choose the plan you wish to move to. To change your plan while you are on the free trial, please cancel your subscription and sign-up once again for the plan you want to experience.'
		},
		{
			query: 'How do I cancel my subscription?',
			response: 'Please click on the Edit plan button. A new page will open with the cancel subscription link.'
		},
		{
			query: 'Can my settings from a previous subscription be restored?',
			response: 'Yes, we can do this for you! Please write to us at support@atomchat.com with your old App ID (that has to be restored) and the new one.'
		},
		{
			query: 'Can I update my billing details?',
			response: 'Yes, please write to us at sales@atomchat.com and we will share a link with you to update the billing details for your subscription.'
		}];

	return (
		data.map((item, index) => <Accordion key={index} heading={item.query} text={item.response} />)
	);
};

const Accordion = ({ heading, text }) => {
	const [expand, setExpand] = useState(false);

	const toggleExpand = () => setExpand(!expand);

	return (
		<div className='accordion'>
			<div className='header' onClick={toggleExpand}>
				<div className="text">{heading}</div>
				{expand ? <CollapseIcon height='2em' /> : <ExpandIcon height='2em' />}
			</div>
			{expand &&
				<div className="detail">
					<div className='text'>{text}</div>
				</div>
			}
		</div>
	);
};

export default Licenses;