import { AtomChatLogo } from '../UIElements/LogoSVGs';
import Button from '../FormElements/Button';
import { Link, useNavigate } from 'react-router-dom';

import './index.css';

const Navigation = ({ screen }) => {
	const navigateTo = useNavigate();

	const setActiveWhen = (name) => {
		if (name === screen) return 'active';
		else return null;
	};

	const signOut = () => {
		localStorage.clear();
		navigateTo('/');
	};

	return (
		<header className="navigation">
			<AtomChatLogo height='2.5em' onClick={() => navigateTo('/user/licenses')} />
			<nav className="nav-items">
				<div className="nav-links">
					<Link className={`item ${setActiveWhen('/user/licenses')}`} to='/user/licenses'>Home</Link>
					<a href='https://help.atomchat.com' target='_blank' className="item" rel="noreferrer">Docs</a>
					<a href='https://www.atomchat.com/contact-us' target='_blank' rel='noreferrer' className="item">Support</a>
					<Link className={`item ${setActiveWhen('/user/profile')}`} to='/user/profile'>Profile</Link>
					<a href='https://help.atomchat.com/support/solutions/82000473604' target='_blank' rel='noreferrer' className="item">Guides</a>
				</div>
				<Button onClick={signOut} >Sign out</Button>
			</nav>
		</header>
	);
};

export default Navigation;