import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import './index.css';
import api from '../../api/dashboard.api';
import { AtomChatLogo } from '../../shared/components/UIElements/LogoSVGs';
import Button from '../../shared/components/FormElements/Button';
import Loading from '../../shared/components/UIElements/Loading';
import { Error, Success } from '../../shared/components/UIElements/Notification';
import { getPlanName } from '../../shared/util';
import { useState } from 'react';
import { useEffect } from 'react';
import Select from 'react-select';

const Payment = () => {
	const [openCheckout, setOpenCheckout] = useState(false);
	const { data, error } = useSWR('/user', api.fetcher);
	const [searchParams] = useSearchParams();
	const navigateTo = useNavigate();
	const productId = searchParams.get('product');
	const userInLS = JSON.parse(localStorage.getItem('auth:user'));
	const { state } = useLocation();
	const fromOnboarding = state && state.fromOnboarding;

	if (!userInLS) {
		return navigateTo(`/register?product=${productId}`, { replace: true });
	}

	if (data) {
		localStorage.setItem('auth:user', JSON.stringify({ ...userInLS, onboardingRequired: false }));
	}

	const openPaddleCheckout = (data) => {
		if (!fromOnboarding) {
			localStorage.setItem('paddle:passthrough', JSON.stringify(data));
		}

		setOpenCheckout(true);
	};
	
	let heading = 'Let\'s complete creating your Trial account!';
	let text = 'You will not be charged until your free trial expires in 14 days and you can cancel anytime during the trial period. No commitments. No hidden costs!';

	if (productId === '65052' || productId === '854847') {
		heading = 'Let\'s complete your Lifetime Plan purchase!';
		text = 'With our 30-day money back guarantee, you are eligible for a full refund within 30 days of this purchase. No questions asked!';
	}

	return (
		<>

			<div className='grid-cols-2'>
				<div className='left-panel'>
					<div style={{ padding: '2em 4em', height: '10vh' }}>
						<AtomChatLogo style={{ cursor: 'pointer' }} height='2.5em' onClick={() => navigateTo('/')} />
					</div>
					<TitleSection>
						<h1>{heading}</h1>
						<p>{text}</p>
					</TitleSection>
				</div>
				<div className="right-panel">
					{
						openCheckout ?
							<PaddleCheckout productId={productId} email={data.email} country={data.country} postcode={data.postcode} />
							:
							<PaymentSection productId={productId} data={data} error={error} openPaddleCheckout={openPaddleCheckout} fromOnboarding={fromOnboarding} />}
				</div>
			</div>
		</>
	);
};

export const TitleSection = ({ children }) => {
	return (
		<section className='payment-container'>
			{children}
		</section>
	);
};

const PaymentSection = ({ productId, data, error, openPaddleCheckout, fromOnboarding }) => {
	const { data: integrations, error: integrationsError } = useSWR('/integrations', api.fetcher);
	const [tech, setSelectedTech] = useState(0);
	const [domain, setDomain] = useState('');
	const [otherText, setOtherText] = useState('');
	const planName = getPlanName(productId);

	if (!data && !error) {
		return <Loading size='large' />;
	}

	if (error) {
		return <div className="outer-container"><Error invert>{error.message}</Error></div>;
	}

	if (!integrations && !integrationsError) {
		return <Loading size='large' />;
	}

	const handleChangeOtherText = ({ target }) => {
		setOtherText(target.value);
	};

	const handleChangeDomainText = ({ target }) => {
		setDomain(target.value);
	};

	const isValidURL = (value) => {
		let timesMustMatch = '*';

		if (/www\./.test(value)) {
			timesMustMatch = '+';
		}

		const pattern = new RegExp(`[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)${timesMustMatch}(\\.[a-z]{2,})(:[0-9]{1,5})?(\\/[a-z0-9\\-._~:\\/?#[\\]@!$&'()*+,;=]*)?`, 'gm');

		return pattern.test(value) || value === 'localhost';
	};

	const categories = ['software', 'language', 'none'];

	const options = categories.map(category => ({
		label: category.toUpperCase(),
		options: integrations.filter(tech => tech.type === category).map(tech => ({ value: tech.id, label: tech.name })),
	}));

	if (!planName) {
		return (
			<section className="payment-container">
				<h2>Looks like you haven&apos;t selected a plan yet!</h2>
				<Button size='large' href='https://www.atomchat.com/pricing' target='_self'>Select a plan now</Button>
			</section>
		);
	}

	const domainAndTech = () => {
		return (
			<div className='passthrough-info' style={{ width: '100%' }}>
				<section className='domain'>
					<h1>Enter your domain</h1>
					<label htmlFor='domain'>Your website URL</label>
					<input onChange={handleChangeDomainText} value={domain} type='text' id='domain' className='other-option' />
					<p>
						This domain should be secure! Don&apos;t have an SSL certificate yet?
						<a href='https://www.gogetssl.com/' target='_blank' rel='noreferrer'> Try  GoGetSSL, </a>
						<a href='https://zerossl.com/' target='_blank' rel='noreferrer'>SSL For Free ZeroSSL.</a></p>
				</section>
				<section className='tech'>
					<h1>Technology</h1>
					<Select options={options} onChange={(item) => setSelectedTech(item.value)} />
				</section>
				{tech === 107 &&
					<section>
						<h2>None of the above?</h2>
						<label htmlFor='other-option'>Please tell us what technology your website uses</label>
						<input onChange={handleChangeOtherText} value={otherText} type='text' id='other-option' className='other-option' />
					</section>
				}
			</div>);
	};

	return (
		<section className='payment-container'>
			<div className='summary-container'>
				<div className="header">Summary</div>
				<div className="details">
					<div className="info"><span className="item">Name </span><span className="value">{`${data.firstName} ${data.lastName}`}</span></div>
					<div className="info"><span className="item">Plan </span><span className="value">{planName}</span></div>
					{!fromOnboarding && domainAndTech()}
				</div>
				<Button disabled={((!fromOnboarding && !tech && !otherText.length) || (!fromOnboarding && !isValidURL(domain)))} size='full' inverse onClick={() => openPaddleCheckout({ domain, web_integration_ids: tech, web_integration_ids_custom: otherText })}>Proceed to Checkout</Button>
			</div>
		</section >
	);
};

const PaddleCheckout = ({ productId, email, country, postcode }) => {
	const navigateTo = useNavigate();
	const [goToDashboard, setGoToDashboard] = useState(false);

	const planName = getPlanName(productId);

	const Paddle = window.Paddle;
	const passthrough = localStorage.getItem('paddle:passthrough');

	const showDashboardButton = () => {
		localStorage.removeItem('paddle:passthrough');
		setGoToDashboard(true);
	};

	useEffect(() => {
		Paddle.Checkout.open({
			product: productId,
			method: 'inline',
			email: email,
			country: country,
			postcode: postcode,
			allowQuantity: false,
			disableLogout: true,
			frameTarget: 'paddle_container',
			frameInitialHeight: '100%',
			frameStyle: 'width: 100%; min-width: 312px; background-color: transparent; margin: auto;',
			successCallback: showDashboardButton,
			passthrough
		});
	}, []);

	return (
		<div style={{ width: '100%', padding: '2em', height: '100vh' }}>
			{goToDashboard ?
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					<Success inverse style={{ fontSize: '2em', padding: 0 }}>Done! Your {planName} license will be available on your dashboard in just a minute!</Success>
					<Button size='full' onClick={() => navigateTo('/user/licenses', { state: { showIntegrationModal: true, redirectedFromPayment: true }})}>Go to your dashboard</Button>
				</div>
				:
				<div className="paddle_container"></div>
			}
		</div >

	);
};

export default Payment;