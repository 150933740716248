import { getAuthHeader, handleFetchApiError } from './common';

/**
 * API to fetch data related to dashboard. 
 * Using fetch instead of axios here because axios caches response which leads to stale data.
 */

const fetcher = async (...args) => {
	const data = await fetch(`/api${args[0]}`, { method: 'GET', headers: getAuthHeader() });
	return data.json();
};

const updateLicenseInfo = async (id, data, path) => {
	try {
		const res = await fetch(`/api/licenses/${id}/${path}`,
			{
				method: 'PUT',
				body: JSON.stringify(data),
				headers: {
					...getAuthHeader(),
					'Content-Type': 'application/json'
				}
			});

		if (!res.ok) {
			const error = new Error('An error occurred while fetching the data.');
			error.info = await res.json();
			error.status = res.status;
			throw error;
		}

		return res.json();
	} catch (error) {
		throw handleFetchApiError(error);
	}
};

const fetchIntegrations = async () => {
	const res = await fetch('/api/integrations/');
	return res.json();
};

const fetchAdminLink = async (id) => {
	try {
		const res = await fetch(`/api/licenses/${id}/admin`,
			{
				method: 'GET',
				headers: {
					...getAuthHeader(),
					'Content-Type': 'application/json'
				}
			});

		if (!res.ok) {
			const error = new Error('An error occurred while fetching the data.');
			error.info = await res.json();
			error.status = res.status;
			throw error;
		}

		return res.json();
	} catch (error) {
		throw handleFetchApiError(error);
	}
};

const updateUserInfo = async (data) => {
	try {
		const res = await fetch('/api/user',
			{
				method: 'PUT',
				body: JSON.stringify(data),
				headers: {
					...getAuthHeader(),
					'Content-Type': 'application/json'
				}
			});

		if (!res.ok) {
			const error = new Error('An error occurred while fetching the data.');
			error.info = await res.json();
			error.status = res.status;
			throw error;
		}

		return res.json();
	} catch (error) {
		throw handleFetchApiError(error);
	}
};

const fetchAppData = async (clientId) => {
	try {
		const res = await fetch(`/api/licenses/data/${clientId}`,
			{
				method: 'GET',
				headers: {
					...getAuthHeader(),
					'Content-Type': 'application/json'
				}
			});


		if (!res.ok) {
			const error = new Error('An error occurred while fetching the data.');
			error.info = await res.json();
			error.status = res.status;
			throw error;
		}

		return res.json();
	} catch (error) {
		throw handleFetchApiError(error);
	}
};

const deletePlans = async (data) => {
	try {
		const res = await fetch('/api/licenses/plans',
			{
				method: 'DELETE',
				headers: {
					...getAuthHeader(),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			});


		if (!res.ok) {
			const error = new Error('An error occurred while fetching the data.');
			error.info = await res.json();
			error.status = res.status;
			throw error;
		}

		return res.json();
	} catch (error) {
		throw handleFetchApiError(error);
	}
};

export default { fetchIntegrations, fetcher, fetchAdminLink, updateLicenseInfo, updateUserInfo, fetchAppData, deletePlans };