import { useForm } from 'react-hook-form';
import { Input } from '../../../shared/components/FormElements/Input';
import Button from '../../../shared/components/FormElements/Button';
import { useNavigate, useParams } from 'react-router-dom';
import useLicense from '../../../shared/hooks/useLicense';
import { Error } from '../../../shared/components/UIElements/Notification';
import Loading from '../../../shared/components/UIElements/Loading';

const Domain = () => {
	const navigateTo = useNavigate();
	const { id } = useParams();
	const { register, formState: { errors }, handleSubmit } = useForm({ mode: 'onTouched' });
	const { license, updateLicenseInfo, loading, error } = useLicense(id);

	if (!id) {
		return navigateTo('/user/licenses');
	}

	if (!license) {
		return <Error invert status={404} />;
	}

	const submitDomainChange = async (data) => {
		await updateLicenseInfo(data, 'domain');
		navigateTo('/user/licenses', { state: { success: true, changed: 'domain' } });
	};

	return (
		<section className="outer-container">
			{error && <Error invert>{error.message}</Error>}
			<h1 className="heading">Domain</h1>
			<div className="container">
				<p>Please enter your domain like <span className='text-red'>atomchat.com</span> or your app package name like <span className='text-red'>com.atomchat.app</span>. During development, you can also use an IP address or <span className='text-red'>localhost</span> as the domain.</p>
				<div className="current">
					<h2>Current Domain</h2>
					<div className="value">{license.domain}</div>
				</div>
				<form className="change" onSubmit={handleSubmit(submitDomainChange)}>
					<Input
						element="input"
						id="domain"
						type="text"
						label="Domain"
						register={register}
						validations={{ required: true }}
						errors={errors.domain}
						errorText="Please enter a domain"
					/>
					<Button disabled={loading} size='full' type='submit'>{loading ? <Loading /> : 'Change'}</Button>
				</form>
			</div>
		</section>
	);
};

export default Domain;