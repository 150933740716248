import { useEffect, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';

import Button from '../../../shared/components/FormElements/Button';
import { Error, Success } from '../../../shared/components/UIElements/Notification';
import Loading from '../../../shared/components/UIElements/Loading';
import { Input, Dropdown } from '../../../shared/components/FormElements/Input';
import validator, { VALID_EMAIL, VALID_PASSWORD } from '../../../shared/util/validators';
import countries from '../../../constants/countries.json';
import { RegisterContext, saveUserActionCreator } from '../store';

import '../common.css';

export const Register = ({ verifyEmail, path }) => {
	const [serverError, setServerError] = useState('');
	const [loading, setLoading] = useState(false);
	const { dispatch } = useContext(RegisterContext);
	const navigateTo = useNavigate();
	const [searchParams] = useSearchParams();
	const productId = searchParams.get('product');
	const isDealify = path === '/dealify';
	const isAppSumo = path === '/appsumo';
	const dealifyToken = isDealify ? searchParams.get('accessToken') : null;
	const appsumoToken = isAppSumo ? searchParams.get('accessToken') : null;

	const { watch, reset, ...formActions } = useForm({
		mode: 'onTouched'
	});

	let emailQuery = '';

	try {
		if (searchParams.get('e')) {
			emailQuery = isAppSumo && window.atob(searchParams.get('e'));
		} else {
			emailQuery = '';
		}
	} catch (error) {
		emailQuery = '';
	}

	if (!productId && dealifyToken !== '74a4356e3a6' && appsumoToken !== '61yvd1f') {
		return window.open('https://www.atomchat.com/pricing', '_self');
	}

	useEffect(() => {
		const userStored = JSON.parse(window.sessionStorage.getItem('register:user'));
		reset({ ...userStored });
	}, []);


	const passwordsMatch = (password) => {
		return password === watch('password');
	};

	const handleRegister = async (data) => {
		setLoading(true);
		setServerError(false);
		delete data.confirmPass;
		delete data.remember;

		const saveInSession = {
			email: data.email,
			first_name: data.first_name,
			last_name: data.last_name,
			country: data.country
		};

		window.sessionStorage.setItem('register:user', JSON.stringify(saveInSession));
		window.localStorage.setItem('paddle:id', productId);

		try {
			await verifyEmail(data.email, data.first_name, dealifyToken);
			dispatch(saveUserActionCreator(data));
			navigateTo('/verify', { state: { dealifyToken } });
		} catch (error) {
			if (error.name === 'ERR_USER_ALREADY_EXISTS') {
				setServerError(<>{error.message} Try to <Link style={{ color: 'yellow', fontWeight: 'bold' }} to='/' state={{ paymentId: productId }}>Log In</Link> instead.</>);
			} else {
				setServerError(error.message);
			}
		}
		setLoading(false);
	};

	return (
		<div className='form-container'>
			{serverError && <Error invert>{serverError}</Error>}
			{isAppSumo && <Success inverse style={{ margin: 0, padding: 0 }}><h4>Welcome Sumo-ling!</h4>Please register with your email to get started with AtomChat.</Success>}
			<h2 className='form-header'>Create your account</h2>
			<RegisterForm handleRegister={handleRegister} passwordsMatch={passwordsMatch} useForm={formActions} loading={loading} emailQuery={emailQuery} />
			<div className='link-txt'>
				Already have an account? <Link className='link-cta' to='/' state={{ paymentId: productId }}>Log In</Link>
			</div>
		</div>
	);
};

export const RegisterForm = ({ handleRegister, passwordsMatch, useForm, loading, emailQuery }) => {
	const { handleSubmit, register, formState: { errors }, control, setValue } = useForm;
	const readOnly = !!emailQuery.length; // turning the integer value into boolean

	readOnly && setValue('email', emailQuery);

	return (
		<form onSubmit={handleSubmit(handleRegister)}>
			<Input
				element="input"
				id="email"
				type="email"
				label="Email"
				register={register}
				validations={validator(VALID_EMAIL(), true)}
				errors={errors.email}
				readOnly={readOnly}
				disabled={readOnly}
				errorText='Please enter a valid email address' />

			<div style={{ display: 'flex', justifyContent: 'space-between', columnGap: '1em' }}>
				<Input
					element="input"
					id="first_name"
					type="text"
					label="First Name"
					register={register}
					validations={{ required: true }}
					errors={errors.first_name}
					errorText='First Name is required'
				/>

				<Input
					element="input"
					id="last_name"
					type="text"
					label="Last Name"
					register={register}
					validations={{ required: true }}
					errors={errors.last_name}
					errorText='Last Name is required'
				/>
			</div>

			<Dropdown
				options={countries}
				control={control}
				errors={errors.country}
				id='country'
				label='Select Your Country'
				validations={{ required: true }}
				errorText='Please select a country' />

			<Input
				element="input"
				id="password"
				type="password"
				label="Password"
				register={register}
				validations={validator(VALID_PASSWORD(), true)}
				errors={errors.password}
				errorText='Please enter a valid password with at least 8 characters including uppercase and numeric characters' />

			<Input
				element="input"
				id="confirmPass"
				type="password"
				label="Confirm Password"
				register={register}
				validations={{ validate: value => passwordsMatch(value) }}
				errors={errors.confirmPass}
				errorText='Password does not match' />

			<div>
				<div className='checkbox-container'>
					<input type="checkbox" name="remember" id="remember" {...register('remember', { required: 'Please accept the Terms of Service' })} />
					<label htmlFor='remember' style={{ marginLeft: '.4em', fontSize: '0.9rem' }}>I agree to AtomChat&apos;s <a href='https://www.atomchat.com/terms-of-service' target='__blank' className='link-cta'>Terms of Service</a> and <a href='https://www.atomchat.com/privacy-policy' className='link-cta' target='__blank'>Privacy Policy</a></label>
				</div>
				{<Error>{errors.remember?.message}</Error>}
			</div>

			<Button size='large' type='submit' style={{ marginTop: '.4rem' }}>{loading ? <Loading /> : 'Sign Up'}</Button>
		</form>
	);
};

export default Register;