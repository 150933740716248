import { useState } from 'react';
import { useForm } from 'react-hook-form';
import sha1 from 'sha1';
import useSWR from 'swr';

import { Dropdown, Input } from '../../../shared/components/FormElements/Input';
import Button from '../../../shared/components/FormElements/Button';
import { useNavigate, useParams } from 'react-router-dom';
import useLicense from '../../../shared/hooks/useLicense';
import dashboardApi from '../../../api/dashboard.api';

import { Error } from '../../../shared/components/UIElements/Notification';
import Loading from '../../../shared/components/UIElements/Loading';
import { AskingQuestion } from '../../../shared/components/UIElements/ImageSvgs';
import { GroupIcon, UserIcon } from '../../../shared/components/UIElements/LogoSVGs';

const Cancellation = () => {
	const { id } = useParams();
	const navigateTo = useNavigate();
	const { license, loading, updateLicenseInfo } = useLicense(id);
	const [error, setError] = useState('');
	const name = JSON.parse(window.localStorage.getItem('auth:user')).firstName;

	if (!license) {
		return <Error status={404} />;
	}

	const handlePlanCancel = async (data) => {
		data.auth = sha1(id + 'cometchat');
		try {
			await updateLicenseInfo(data, 'cancel');
			navigateTo('/user/licenses', { state: { success: true, changed: 'cancellation' } });
		} catch (error) {
			setError(error.message);
		}
	};

	return (
		<>
			<section id='cancellation'>
				{error && <Error invert>{error}</Error>}
				<h1>{name && `${name}, `}We&apos;re sorry to see you go :(</h1>
				<CommunityStats id={id} />
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Reason loading={loading} handlePlanCancel={handlePlanCancel} />
					<AskingQuestion height='30em' />
				</div>
			</section >
		</>
	);
};

const CommunityStats = ({ id }) => {
	const { data } = useSWR('/licenses/data/' + id, dashboardApi.fetcher);

	if (!data) {
		return null;
	}

	if (data.users < 10) {
		return null;
	}

	return (
		<div className="community-stats center">
			<div className="info-box-sm">
				<h2>Your website has...</h2>
				<div className="stats">
					<div className="stat"><UserIcon height='32' /> <div className="text">Users:<span className="value">{data ? data.users : <Loading />}</span></div></div>
					<div className="stat"><GroupIcon height='32' /><div className="text">Groups:<span className="value">{data ? data.groups : <Loading />}</span></div></div>
				</div>
				<div className="stat"><div className="value">and <b>{data ? data.totalMessages : <Loading />}</b> messages exchanged</div></div>
				<p>Are you sure you want to cancel?</p>
			</div>
		</div>
	);
};

const Reason = ({ loading, handlePlanCancel }) => {
	const { register, formState: { errors }, handleSubmit, control, watch, setValue } = useForm({ mode: 'onTouched' });
	const [reason, setSelectReason] = useState('');
	// const [detail, setDetail] = useState('');

	const options = [
		{ value: 'testing_atomchat', label: 'I was testing and only wanted the trial.' },
		{ value: 'no_longer_needed', label: 'I no longer needed it.' },
		{ value: 'monthly_pricing', label: 'I wanted monthly billing.' },
		{ value: 'pricing', label: 'It was too expensive.' },
		{ value: 'lack_integration', label: 'Integrating AtomChat was a challenge.' },
		{ value: 'technical_difficulties', label: 'I encountered several technical difficulties.' },
		{ value: 'lack_documentation', label: 'Documentation was insufficient.' },
		{ value: 'feature_limitation', label: 'It lacks the capabilities and functionality that I require.' },
		{ value: 'alternative', label: 'I found an alternative.' },
		{ value: 'not_sure_use', label: 'I am not sure how to use it.' },
		{ value: 'quality_issue', label: 'The quality was less than expected.' },
		{ value: 'bad_experience', label: 'I had a bad user experience.' },
		{ value: 'ease_of_use', label: 'Ease of use was less than expected.' },
		{ value: 'will_back', label: 'This is only temporary. I will be back!' },
		{ value: 'other', label: 'Other' }
	];

	const reasons = [
		{ value: 'lack_integration', inputText: 'Please share which integrations would you like us to have.' },
		{ value: 'pricing', inputText: 'Any suggestions about how we could improve our pricing to better suit your needs?' },
		{ value: 'feature_limitation', inputText: 'Which feature(s) would you like to see improved and how?' },
		{ value: 'alternative', inputText: 'Out of curiosity, which solution did you go for, and why?' },
		{ value: 'quality_issue', inputText: 'Please specify.' },
		{ value: 'bad_experience', inputText: 'Please share how we can improve the experience.' },
		{ value: 'ease_of_use', inputText: 'Please specify.' },
		{ value: 'other', inputText: 'My reason was not present in the list. I want to cancel because' }
	];


	const labelObj = reasons.find(reason => reason.value === watch('why'));
	const detail = watch('reason');

	const handleChangeWhy = (value) => {
		setSelectReason(value);
		setValue('why', value);
	};

	return (
		<div className='cancellation-info'>
			<h2>Please let us know your concerns</h2>
			<form className="change" onSubmit={handleSubmit(handlePlanCancel)}>
				<Dropdown
					options={options}
					id="why"
					label="Reason"
					validations={{ required: true }}
					errors={errors.why}
					errorText="Please select a reason"
					control={control}
					onChange={(value) => handleChangeWhy(value)}
				/>
				{labelObj &&
					<Input
						element="input"
						id="reason"
						type="text"
						label={labelObj.inputText}
						register={register}
						validations={{ required: true }}
						errors={errors.reason}
						errorText="Please specify the reason"
					/>
				}
				<div className="text">
					<p>Our team is here to work with you and address your concerns. Before cancelling, please feel free to <a className='text-red' href='https://calendly.com/atomchat-support'>schedule a meeting with us</a>.</p>
					<p>Or email us at <a className='text-red' href="mailto:support@atomchat.com">support@atomchat.com</a>.</p>
					<p>Do you still want to proceed to cancel?</p>
				</div>
				<Button disabled={loading || !reason || (labelObj && !detail)} type='submit'>{loading ? <Loading /> : 'Proceed'}</Button>
			</form >
		</div >
	);
};

export default Cancellation;