import useSWR from 'swr';
import { useForm } from 'react-hook-form';
import { Dropdown, Input } from '../../../shared/components/FormElements/Input';
import Button from '../../../shared/components/FormElements/Button';
import api from '../../../api/dashboard.api';
import Loading from '../../../shared/components/UIElements/Loading';
import useLicense from '../../../shared/hooks/useLicense';
import { Error } from '../../../shared/components/UIElements/Notification';
import { useNavigate, useParams } from 'react-router-dom';

const Technology = () => {
	const navigateTo = useNavigate();
	const { id } = useParams();
	const { register, formState: { errors }, handleSubmit, control, watch } = useForm({ mode: 'onTouched' });
	const { data, error } = useSWR('/integrations', api.fetcher);
	const { license, updateLicenseInfo, loading } = useLicense(id);

	if (!data && !error) {
		return (
			<div style={{ height: '80vh' }} className='center'>
				<Loading size='large' />
			</div>
		);
	}

	if (!license) {
		return <Error status={404} />;
	}

	const categories = ['software', 'language', 'none'];
	const options = categories.map(category => ({
		label: category.toUpperCase(),
		options: data.filter(tech => tech.type === category).map(tech => ({ value: tech.id, label: tech.name })),
	}));

	const submitTechChange = async (data) => {
		await updateLicenseInfo(data, 'integration');
		navigateTo('/user/licenses', { state: { success: true, changed: 'technology' } });
	};

	const isUnlisted = watch('webIntegrationID') === 107;

	return (
		<section className="outer-container">
			<h1 className="heading">Technology</h1>
			<div className="container">
				<p>Please select the technology you are using to build your website.</p>
				<div className="current">
					<h2>Current Technology</h2>
					<div className="value">{license.tech}</div>
				</div>
				<form className="change" onSubmit={handleSubmit(submitTechChange)}>
					<Dropdown
						options={options}
						id="webIntegrationID"
						label="Technology"
						register={register}
						validations={{ required: true }}
						errors={errors.webIntegrationID}
						errorText="Please select a technology"
						control={control}
					/>
					{isUnlisted &&
						<Input
							element="input"
							id="webIntegrationCustom"
							type="text"
							label={'Name'}
							register={register}
							validations={{ required: true }}
							errors={errors.webIntegrationCustom}
							errorText="Please specify the reason"
						/>
					}
					<Button disabled={loading} size='full' type='submit'>{loading ? <Loading /> : 'Change'}</Button>
				</form>
			</div>
		</section>
	);
};

export default Technology;